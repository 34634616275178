<template>
    <div class="activeTask-detail">
        <app-header :title="title" :isShowBack="true"></app-header>
        <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    </div>
</template>
<script>
    export default {
        data() {
            let _this = this;
            return {
                title: "消费详情",
                opt: {
                    columns: [{
                            label: "子订单号",
                            key: "orderId"
                        },{
                            label: "货柜柜门",
                            key: "doorCode"
                        },{
                            label: "商品图片",
                            key: "pic",
                            type: "img-shop"
                        },
                        {
                            label: "商品品类",
                            key: "categoryName"
                        },
                        {
                            label: "商品名称",
                            key: "itemName"
                        },
                        {
                            label: "商品编号",
                            key: "itemCode"
                        },
                        {
                            label: "商品售价（元）",
                            key: "sellPrice"
                        },
                        {
                            label: "商品成本价（元）",
                            key: "costPrice"
                        },
                        {
                            label: "商品详细描述",
                            key: "itemDetail"
                        },
                        {
                            label: "支付状态",
                            key: "stateStr"
                        },
                        {
                            label: "取货状态",
                            key: "pickUpStateStr"
                        },
                    ],
                    buttons: []
                },
            };
        },
        created() {},
        activated() {},
        methods: {
            onGet(opt) {
                let dto = {
                    pageNum: opt.skip,
                    pageSize: opt.limit,
                    taskId: this.$route.query.id,
                    ...opt.searchForm
                };
                this.post("/mall-service/v1/activity/task/get-order-by-task-id", dto, {
                    isUseResponse: false
                }).then(res => {
                    let obj = {
                        data: [],
                        total: 0,
                    }
                    obj.data = res.records;
                    obj.total = res.total
                    if (obj.data && obj.data.length > 0) {
                        obj.data.forEach(item => {
                            item.stateStr = ["未支付", "支付中", "支付成功", "支付失败", "订单取消", "支付超时", "支付成功已退款", "支付成功待退款", "支付成功退款失败"][item.state - 1];
                            item.pickUpStateStr = ['未取货', '已取货'][item.isTake];
                        });
                    }
                    opt.cb(obj);
                });
            },
        }
    };
</script>
<style lang="scss" scoped>
.rechargeManagement-detail {
    height: calc(100% - 78px);
}
</style>